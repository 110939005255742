@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
    url('DINPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('DINPro-Bold.woff2') format('woff2'),
    url('DINPro-Bold.woff') format('woff'),
    url('DINPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
    url('DINPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('DINPro-Medium.woff2') format('woff2'),
    url('DINPro-Medium.woff') format('woff'),
    url('DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
    url('DINPro.eot?#iefix') format('embedded-opentype'),
    url('DINPro.woff2') format('woff2'),
    url('DINPro.woff') format('woff'),
    url('DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



