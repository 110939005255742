@import "../vars";

@page {
  size: A4;
  margin: 10mm 10mm 10mm 10mm;
}

.JobDetail {
  display: flex;
  flex-direction: column;

  @include content-padding;

  @media print {
    font-size: 12px;
    color: #000;

    .heroImage {
      display: block;
      width: 100%;
      height: auto;
    }
    .button {
      @include dont-print;
    }
    p,
    h1,
    h2,
    h3,
    ul {
      overflow: visible;
      break-inside: avoid;
      page-break-inside: avoid; // for older browsers
      display: inline-block;
      position: static;
      width: 100%;
    }

    .avoid-break {
      page-break-inside: avoid;
      overflow: visible;
      display: table;
      p,
      p p {
        page-break-inside: avoid;
        overflow: visible;
        display: table;
      }
    }
  }

  .JobDetail-Content {
    display: grid;
    width: 100%;
    max-width: $max-width;
    grid-gap: 128px;
    grid-template-columns: 6fr 4fr;
    margin: 0 auto;
    align-items: start;
    @media screen and (max-width: $bp-phone) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
    @media print {
      display: flex;
      flex-direction: row;
      grid-gap: 10mm;
      gap: 10mm;
      flex-grow: 1;
    }
    h1 {
      @include fp(font-size, 20, 40, 320, $max-width-pure);
      @media print {
        font-size: 20px;
      }
    }
    h2 {
      @include fp(font-size, 18, 32, 320, $max-width-pure);
      margin-top: 1.5em;
      @media print {
        font-size: 16px;
      }
      &.sub {
        @include fp(font-size, 14, 24, 320, $max-width-pure);
        @media print {
          font-size: 10px;
        }
      }
    }
  }

  .JobDetail-Details {
    display: flex;
    flex-direction: column;
    gap: 64px;
    @media print {
      gap: 16px;
      width: 30%;
      min-width: 50mm;
      //flex-grow: 1;
    }
    @media screen and (min-width: $bp-phone) {
      position: sticky;
      top: 96px;
    }
  }

  .JobDetail-Detail {
    display: flex;
    margin-bottom: 8px;
    @media print {
      flex-direction: column;
    }
    .label {
      width: 100px;
      flex-shrink: 0;
      line-height: 1.6;
      @media print {
        width: 20mm;
      }
    }
    .value {
      font-weight: 500;
      word-break: break-word;
      word-wrap: break-word;
      line-height: 1.6;
      @media print {
        font-weight: 400;
      }
    }
  }

  .JobDetail-Text {
    ul {
      list-style: square;
      li {
        margin-bottom: 0.5em;
      }
    }
  }

  .label {
    font-weight: 500;
    color: rgba(22, 65, 150, 0.6);
    @media print {
      color: #000;
    }
  }

  .JobDetail-Teams {
    margin-top: 64px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .printOnly {
    display: none;

    @media print {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10mm;
      .qr {
        width: 30mm;
        background: #fff;
        svg {
          display: block;
          width: 100%;
          height: auto;
          max-width: 20mm;
          margin-top: 4mm;
          background: #fff;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
