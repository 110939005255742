@import "../vars";

.Facts {
  width: 100%;
  max-width: $max-width;
  display: flex;
  justify-content: center;
  align-items: center;
  @include content-margin;
  @include content-padding;

  .Facts-Content {
    padding: 32px 0;
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: row;
    background: #f6f6f6;
    @media screen and (max-width: $bp-phone) {
      flex-direction: column;
      padding: 0 32px;
    }
    .Fact {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      padding: 32px;
      gap: 16px;
      border-right: 1px solid rgba(22, 65, 150, 0.2);
      @media screen and (max-width: $bp-phone) {
        border-right: none;
        border-bottom: 1px solid rgba(22, 65, 150, 0.2);
      }
      &:last-child {
        border-right: none;
        border-bottom: none;
      }

      .Fact-number {
        font-size: 64px;
        font-weight: 700;
      }
    }
  }
}
