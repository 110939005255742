@import "../vars";

.Image {
  @media print {
    width: 100%;
    height: auto;
    display: block;
    img {
      width: 100%;
      height: auto;
      display: block;
      aspect-ratio: 16/9;
    }
  }
}
