@import "../vars";

.PhotoSlider {
  display: flex;
  width: 100%;
  max-width: $max-width;

  @include content-margin;
  @include content-padding;

  .swiper-button-prev {
    width: 48px;
    height: 48px;
    background: url("../assets/ic_slider_prev_white.svg");
    background-size: 100% 100%;
    &:after {
      content: none;
    }
  }
  .swiper-button-next {
    width: 48px;
    height: 48px;
    background: url("../assets/ic_slider_next_white.svg");
    background-size: 100% 100%;
    &:after {
      content: none;
    }
  }
  .swiper-button-disabled {
    opacity: 0;
    &:hover {
      opacity: 0;
    }
  }
  .swiper-scrollbar {
    background: $is-grey;
    .swiper-scrollbar-drag {
      background: $is-rot;
    }
  }
}
