@import "../vars";

.ContentSlider {
  display: flex;
  width: 100%;
  margin: 10vh auto;
  max-width: $max-width;
  //min-height: 80vh;
  aspect-ratio: 16 / 9;
  position: relative;

  @media screen and (max-width: $bp-phone) {
    min-height: auto;
    aspect-ratio: auto;
  }

  .ContentValue {
    display: grid;
    background: $is-blau;
    color: #fff;
    grid-template-columns: 4fr 4fr;
    height: 100%;
    user-select: none;
    cursor: grab;

    @media screen and (max-width: $bp-phone) {
      display: flex;
      flex-direction: column;
    }

    .ContentValue-Text {
      padding: 64px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media screen and (max-width: $bp-phone) {
        padding: 32px;
        padding-top: 128px;
        flex-grow: 1;
        padding-bottom: 80px;
      }
      h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
      h3 {
        font-size: 36px;
        line-height: 1.4;
        margin: 0;
      }
      p {
        margin: 0 0 1em;
      }
      ul {
        list-style: square;
      }
    }
    .ContentValue-Image {
      height: 100%;
      display: flex;
      @media screen and (max-width: $bp-phone) {
        height: 30vh;
      }
      picture {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background: url("../assets/ic_slider_prev_white.svg");
    background-size: 100% 100%;

    top: auto;
    left: 64px;
    right: auto;
    bottom: 20px;

    &:after {
      content: none;
    }
  }
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background: url("../assets/ic_slider_next_white.svg");
    background-size: 100% 100%;

    top: auto;
    left: 64px + 40px + 16px;
    right: auto;
    bottom: 20px;

    &:after {
      content: none;
    }
  }
  .swiper-button-disabled {
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
    }
  }
  .swiper-scrollbar {
    background: $is-grey;
    .swiper-scrollbar-drag {
      background: $is-rot;
    }
  }
  .swiper-pagination {
    display: flex;
    align-items: center;
    color: #fff;
    left: 64px + 40px + 16px + 64px;
    bottom: 20px;
    height: 40px;
    right: auto;
    width: auto;
    text-align: left;
  }

  @media screen and (max-width: $bp-phone) {
    .swiper {
      //position: sticky;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      &:after {
        content: "";
        display: block;
        background: rgba($is-blau, 0.9);
        top: 64px;
        z-index: 50;
        left: 0;
        right: 0;
        height: 64px;
        position: fixed;
        opacity: 0;
        transition: 0.3s ease all;
      }
    }
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
      position: fixed;
      bottom: auto;
      //top: -100%;
      top: 64px + 16px;
      margin: 0;
      opacity: 0;
      pointer-events: none;
      z-index: 60;
    }
    .swiper-wrapper {
      order: 1;
    }
    .swiper-button-next {
      left: 32px + 40px + 16px;
    }
    .swiper-button-prev {
      left: 32px;
    }
    .swiper-pagination {
      left: 32px + 40px + 16px + 64px;
    }
  }

  &.inView {
    .swiper {
      &:after {
        opacity: 1;
      }
    }
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
