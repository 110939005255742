@import "../vars";

.Advantage {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  user-select: none;
  .Advantage-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: $is-grey;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    img {
      width: 48px;
    }
    @media screen and (max-width: $bp-phone) {
      width: 48px;
      height: 48px;
      padding: 8px;
      img {
        width: 24px;
      }
    }
  }
  .Advantage-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .headline {
      margin: 0;
      word-break: break-word;
      // allow words to break with hyphens on phones
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;

      -webkit-hyphenate-limit-before: 3; /* For Safari */
      -webkit-hyphenate-limit-after: 4; /* For Safari */
      -ms-hyphenate-limit-chars: 10 3 4;
      hyphenate-limit-chars: 10 3 4;
    }
    p {
      margin: 0;
      @media screen and (max-width: $bp-phone) {
        font-size: 12px;
      }
    }
  }
}
