@import "../vars";

.Advantages {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @include content-margin;

  .Advantages-text {
    width: 100%;
    max-width: $max-width;
    @include content-padding;
    margin: 0 auto;
    span {
      text-align: center;
    }
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
      .row {
        flex-direction: column;
      }
    }
  }

  .Advantages-Slider {
    width: 100%;
    max-width: $max-width;
    padding: $padding-desktop;
    margin-bottom: 32px;
    @media screen and (max-width: $bp-phone) {
      padding: 32px $padding-mobile;
    }
    &.swiper,
    .swiper-wrapper {
      overflow: visible;
    }
    .swiper-button-prev {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_prev.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-next {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_next.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-disabled {
      opacity: 0;
      &:hover {
        opacity: 0;
      }
    }
    .swiper-scrollbar {
      background: $is-grey;
      width: auto;
      left: $padding-desktop;
      right: $padding-desktop;
      max-width: $max-width;
      overflow: hidden;
      .swiper-scrollbar-drag {
        background: $is-rot;
      }
    }
  }

  .row {
    @media screen and (min-width: $bp-phone) {
      align-items: center;
    }
    .col {
      &.right {
        align-items: flex-end;
      }
    }
  }

  .Advantages-Filter {
    display: inline-flex;
    flex-direction: row;
    background: $is-grey;
    color: $is-blau;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 16px;
    border-radius: 18px;
    @media screen and (max-width: $bp-phone) {
      gap: 0;
    }
    li {
      display: flex;
      padding: 8px 32px;
      border-radius: 18px;
      cursor: pointer;
      user-select: none;
      @media screen and (max-width: $bp-phone) {
        font-size: 12px;
        padding: 8px 16px;
        text-align: center;
        align-items: center;
      }
    }
    .active {
      background: $is-blau;
      color: #fff;
    }
  }

  .Advantages-Content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
