@import "../vars";

.fadeTimeline-enter {
  opacity: 0;
  z-index: 1;
  position: absolute;
}
.fadeTimeline-enter.fadeTimeline-enter-active {
  opacity: 1;
  position: absolute;

  transition: opacity 250ms ease-out, transform 1000ms ease;
}
.fadeTimeline-exit {
  opacity: 1;
  transform: translate(0, 0);
  position: absolute;
}
.fadeTimeline-exit.fadeTimeline-exit-active {
  opacity: 0;

  transition: opacity 250ms ease-out, transform 1000ms ease;
}

.Timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  position: relative;

  @media screen and (min-width: $bp-phone) {
    flex-direction: row;
    gap: 64px;
    align-items: flex-start;
    //display: grid;
    //grid-gap: 64px;
    //grid-template-columns: 4fr 4fr;
  }

  @include content-padding;
  @include content-margin;

  .Timeline-Detail {
    @media screen and (max-width: $bp-phone) {
      display: none;
    }

    position: sticky;
    top: 24px;
    width: 50%;

    .Timeline-Detail-AnimationWrapper {
      width: 100%;
      left: 0;
      right: 0;
      max-width: 100%;
    }

    .Timeline-Detail-Text {
      //position: sticky;
      top: 0;
    }

    h2 {
      margin-top: 0;
    }
  }

  .Timeline-List {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
