@import "../vars";

.Quote {
  display: flex;
  flex-direction: column;
  padding: 128px 16px;
  background: $is-blau;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $bp-phone) {
    padding: 32px 16px;
  }
  .headline {
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    font-weight: 500;
  }
  .text {
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    max-width: 960px;
    @media screen and (max-width: $bp-phone) {
      font-size: 24px;
    }
  }
}
