@import "../vars";

.Team-Jobs {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  @include content-padding;
  @include content-margin;

  .Team-Jobs-List {
    display: flex;
    flex-direction: column;
  }
}
