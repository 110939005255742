@import "../vars";

.TextContent {
  display: grid;
  width: 100%;
  max-width: $max-width;
  grid-gap: 64px;
  grid-template-columns: 4fr 4fr;
  align-items: center;

  @include content-padding;
  @include content-margin;

  &.TextContent-WithoutImage {
    grid-template-columns: 4fr;
    .TextContent-text {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  @media screen and (max-width: $bp-phone) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .TextContent-image {
    height: 100%;
    order: 0;
    display: flex;
    overflow: hidden;
    align-items: center;

    @media screen and (max-width: $bp-phone) {
      //padding: 0 16px;
    }

    picture,
    img {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media screen and (max-width: $bp-phone) {
      width: 100%;
      max-width: 100%;
      position: relative;
      picture,
      img {
        max-height: 60vh;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  .TextContent-text {
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media screen and (max-width: $bp-phone) {
      padding: 32px 0 0px;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    h2 {
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      @media screen and (max-width: $bp-phone) {
        margin: 0;
        white-space: break-spaces;
        word-break: break-word;

        -webkit-hyphenate-limit-before: 7; /* For Safari */
        -webkit-hyphenate-limit-after: 7; /* For Safari */
        -ms-hyphenate-limit-chars: 10 7 7;
        hyphenate-limit-chars: 10 7 7;
      }
    }
    p {
      font-weight: 400;
      white-space: pre-wrap;
    }
    ul {
      //font-size: 24px;
      font-weight: 500;
      margin-left: 0;
      padding: 16px;
      //list-style: none;
      //@include fp(font-size, 18, 24, 320, $max-width-pure);
      list-style: square;
      li {
        padding: 0;
        margin-bottom: 24px;

        //&::before {
        //  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        //  color: $red; /* Change the color */
        //  font-weight: bold; /* If you want it to be bold */
        //  display: inline-block; /* Needed to add space between the bullet and the text */
        //  width: 1em; /* Also needed for space (tweak if needed) */
        //  margin-left: -1em; /* Also needed for space (tweak if needed) */
        //}
      }
    }
    em {
      font-family: $font_radial;
      font-weight: 700;
      //color: $red;
      font-style: normal;
    }
  }

  &.odd {
    @media screen and (min-width: $bp-phone) {
      .TextContent-image {
        order: 1;
      }
    }
  }
}
