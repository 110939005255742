@import "../vars";

.Page404 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  @include content-padding;
  @include content-margin;
}
