@import "../vars";

.Footer {
  background: rgba(246, 246, 246, 1);
  padding-top: 64px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include dont-print;

  @media screen and (max-width: $bp-phone) {
    padding: $padding-mobile;
  }
  .Footer-Content {
    width: 100%;
    max-width: $max-width;
    @media screen and (max-width: $bp-phone) {
      padding: 0;
    }
    a {
      text-decoration: none;
      //color: rgba(109, 109, 109, 1);
      font-weight: 500;
    }
    hr {
      margin: 32px auto;
    }
    .col.legal {
      flex-direction: row;
      gap: 16px;
      a {
        display: inline-flex;
        color: rgba(109, 109, 109, 1);
        font-weight: 400;
        margin-right: 8px;

        @media screen and (min-width: $bp-phone) {
          margin-left: 16px;
        }
      }
    }
    .col.social {
      flex-grow: 1;
      min-width: 320px;
      @media screen and (min-width: $bp-phone) {
        white-space: nowrap;
        min-width: 30%;
      }
      a {
        display: inline-flex;
        @media screen and (min-width: $bp-phone) {
          margin-right: 8px;
        }
        img {
          width: 40px;
        }
      }
    }
    .row {
      @media screen and (min-width: $bp-phone) {
        gap: 128px;
      }
      @media screen and (max-width: $bp-phone) {
        flex-direction: column;
        .col.legal {
          flex-direction: column;
        }
      }
    }
  }
}
