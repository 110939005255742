@import "../vars";

.MemberSliderMember {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  text-decoration: none;
  user-select: none;
  picture {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .MemberSliderMember-text {
    z-index: 10;
    padding: 64px 16px 16px;
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0.6) 100%
    );

    .name {
      color: #fff;
      margin: 0 0 8px;
      font-size: 36px;
    }
    .department {
      background: $is-blau;
      padding: 4px;
      color: #fff;
      margin: 0;
      display: inline-flex;
    }
  }
}
