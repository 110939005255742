@import "../vars";

.Team {
  display: flex;
  flex-direction: column;
  color: $is-text;
  text-decoration: none;
  background: #f6f6f6;
  margin-bottom: 32px;
  .Team-image {
    img,
    picture {
      width: 100%;
    }
  }
  .Team-text {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h2 {
      font-size: 28px;
      line-height: 1.6;
      margin-top: 0;
      margin-bottom: 0;
      word-break: break-word;
      white-space: break-spaces;

      -webkit-hyphenate-limit-before: 7; /* For Safari */
      -webkit-hyphenate-limit-after: 7; /* For Safari */
      -ms-hyphenate-limit-chars: 10 3 4;
      hyphenate-limit-chars: 10 7 4;
    }
    h3 {
      font-size: 16px;
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
