@import "../vars";

.Video-Solo {
  .Video-Embed {
    @include content-padding;
    @include content-margin;

    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}
