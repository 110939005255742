@import "../vars";

.Hero {
  display: flex;
  width: 100%;
  height: 40vh;
  min-height: 300px;
  max-height: 600px;
  position: relative;
  align-items: flex-end;
  &.Hero-Big {
    height: 50vh;
    min-height: 600px;
    max-height: 800px;
  }
  @media screen and (max-width: $bp-phone) {
    height: 50vh;
    min-height: 250px;
  }
  .Hero-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    img,
    picture {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .Hero-text {
    position: relative;
    color: #fff;

    @include content-padding;
    @include content-margin;

    width: 100%;
    max-width: $max-width;

    //@include content-padding;
    .Hero-buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      @media screen and (max-width: $bp-phone) {
        flex-direction: column;
      }
    }
  }
  .button {
    &.button-secondary {
      background: transparent;
      border: 2px solid #fff;
      color: #fff;
      &:visited {
        color: #fff;
      }
    }
  }
}
