$font-radial: "Radial", "DIN Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu";
$font-din: "DIN Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

$is-rot: #e30613;
$is-blau: #164194;
$is-text: #164196;

$is-grey: #f6f6f6;

$red: $is-rot;
$red-shade: #f03755;
$red-shade-darker: #b2293f;

$max-width: 1320px;
$max-width-pure: 1320;
$bp-phone: 800px;
$header-height: 86px;

$padding-desktop: 32px;
$padding-mobile: 16px;

@mixin fp(
  $property,
  $min,
  $max,
  $start: 320,
  $end: $max-width,
  $clip: true,
  $clipAtStart: true,
  $clipAtEnd: true
) {
  $multiplier: calc(($max - $min) / ($end - $start)) * 100;
  $adder: calc(($min * $end - $max * $start) / ($end - $start));
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

@mixin content-margin {
  margin: 32px auto;

  @media screen and (max-width: $bp-phone) {
    margin: 32px auto;
  }
}

@mixin content-padding {
  padding: $padding-desktop;

  @media screen and (max-width: $bp-phone) {
    padding: $padding-mobile;
  }
}

@mixin dont-print {
  @media print {
    display: none !important;
  }
}
