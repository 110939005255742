@import "../vars";

.TeamMember {
  width: 100%;
  max-width: $max-width;

  @include content-padding;
  @include content-margin;

  display: grid;
  grid-gap: 32px;
  grid-template-columns: 4fr 4fr;
  align-items: flex-start;

  @media screen and (max-width: $bp-phone) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .TeamMember-image {
    min-width: 50%;
    display: flex;

    position: relative;
    @media screen and (min-width: $bp-phone) {
      @include content-padding;
      position: sticky;
      top: 32px;
    }

    picture {
      display: flex;
      height: 100%;
      object-fit: cover;
      max-height: 70vh;
      object-position: center top;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }

    .swiper-button-prev {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_prev.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-next {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_next.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-disabled {
      opacity: 0;
      &:hover {
        opacity: 0;
      }
    }
    .swiper-scrollbar {
      background: $is-grey;
      .swiper-scrollbar-drag {
        background: $is-rot;
      }
    }
  }
  .TeamMember-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fff;
    flex-grow: 1;
    @media screen and (min-width: $bp-phone) {
      @include content-padding;
    }
    h2 {
      font-size: 36px;
      line-height: 1.6;
      margin-top: 0;
      margin-bottom: 0;
      word-break: break-word;
      white-space: break-spaces;
      hyphens: auto;
    }
    p {
      margin: 0;
    }

    .TeamMember-Details {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }

    .TeamMember-Detail {
      display: flex;
      border-top: 1px solid rgba(22, 65, 150, 0.2);
      padding: 16px;
      &:last-child {
        border-bottom: 1px solid rgba(22, 65, 150, 0.2);
      }
      .label {
        width: 100px;
        min-width: 100px;
      }
      .value {
        font-weight: 500;
      }
    }
    .label {
      font-weight: 500;
      color: rgba(22, 65, 150, 0.6);
    }
  }

  &.odd {
    @media screen and (min-width: $bp-phone) {
      .TeamMember-image {
        order: 5;
      }
    }
  }
}
