@import "../vars";

.TimelinePoint {
  $padding: 32px;
  $bubbleSize: 12px;
  $barWidth: 4px;
  $gap: 32px;

  background: $is-blau;
  color: #fff;
  padding: 16px;
  cursor: pointer;
  width: calc(100% - $bubbleSize - $padding);
  position: relative;

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  .TimelinePoint-Description {
    margin-top: 16px;
  }

  @media screen and (max-width: $bp-phone) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: $bubbleSize;
      height: $bubbleSize;
      background: $is-blau;
      border-radius: 50%;
      left: calc(100% + $padding - $bubbleSize/2);
      top: calc(50% - $bubbleSize/2);
      z-index: 10;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: $barWidth;
      height: calc(100% + $gap);
      background: $is-grey;
      left: calc(100% + $padding - $barWidth/2);
      top: 0;

      z-index: 5;
    }
    &:first-child {
      &::after {
        top: calc(50% - $bubbleSize/2);
      }
    }
    &:last-child {
      &::after {
        top: 0;
        bottom: calc(50% - $bubbleSize/2);
        height: 50%;
      }
    }
  }

  @media screen and (min-width: $bp-phone) {
    max-width: calc(50% - $padding);

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: $bubbleSize;
      height: $bubbleSize;
      background: $is-blau;
      border-radius: 50%;
      left: calc(100% + $padding - $bubbleSize/2);
      top: calc(50% - $bubbleSize/2);
      z-index: 10;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: $barWidth;
      height: calc(100% + $gap);
      background: $is-grey;
      left: calc(100% + $padding - $barWidth/2);
      top: 0;

      z-index: 5;
    }
    &:first-child {
      &::after {
        top: calc(50% - $bubbleSize/2);
      }
    }
    &:last-child {
      &::after {
        top: 0;
        bottom: calc(50% - $bubbleSize/2);
        height: 50%;
      }
    }
    &:nth-child(2n) {
      align-self: flex-end;
      &::before {
        left: auto;
        right: calc(100% + $padding - $bubbleSize/2);
      }
      &::after {
        left: auto;
        right: calc(100% + $padding - $barWidth/2);
      }
    }
    .TimelinePoint-Description {
      display: none;
    }
  }
}
