@import "../vars";

.Values {
  width: 100%;
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;

  @include content-padding;
  @include content-margin;

  margin-top: 0;
  margin-bottom: 0;
}

.Values-Container {
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: row;

  @media screen and (max-width: $bp-phone) {
    flex-direction: column;
    gap: 64px;
  }
}

.Value {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  background: $is-grey;
  padding: 16px;

  .Value-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -80px;

    width: 100%;
    height: 180px;
    flex-shrink: 0;
    @media screen and (max-width: $bp-phone) {
      margin-top: -60px;
      height: 120px;
      width: 120px;
    }
    picture {
      width: 60%;
      @media screen and (max-width: $bp-phone) {
        height: 100%;
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .Value-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .headline {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
