@import "_vars.scss";

.row {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.button {
  appearance: none;
  display: inline-flex;
  background: $is-blau;
  color: #fff;
  flex-grow: 0;
  width: auto;
  text-decoration: none;
  padding: 16px 40px;
  border: none;
  border-radius: 28px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  user-select: none;
  font-weight: 500;
  @media screen and (max-width: $bp-phone) {
    padding: 12px 32px;
  }
  &:hover {
    background: mix($is-blau, #000, 95);
  }
  &:visited {
    color: #fff;
  }
  &.button-secondary {
    color: $is-blau;
    background: $is-grey;
    &:hover {
      background: rgba($is-grey, 0.9);
    }
    &:visited {
      color: $is-blau;
    }
  }
}

.text-right {
  text-align: right;
}

.suspenseSection {
  position: relative;
  .suspenseFallback {
    width: 100%;
    text-align: center;
  }
  &.videoSection {
    width: 100%;
    .suspenseFallback {
      aspect-ratio: 16/9;
      background: #fafafa;
    }
  }
  &.multiMapSection {
    min-height: 60vh;
    @media screen and (max-width: $bp-phone) {
      margin-bottom: 32px;
    }
  }
}

.embed-responsive-item {
  width: 100%;
  display: flex;
}
