@import "../vars";

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 16px 16px;
  position: sticky;
  top: 0;
  left: 0;
  height: $header-height;
  right: 0;
  z-index: 9999;
  background: #fff;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  @include dont-print;

  @media screen and (max-width: 1200px) {
    height: auto;
    position: fixed;
    padding: 0;
    top: 0;
  }

  @media screen and (max-width: $bp-phone) {
    height: auto;
    position: fixed;
    padding: 0;
    top: 0;
  }

  &.hide {
    @media screen and (min-width: $bp-phone) {
      top: -100px;
    }
  }

  .logo {
    width: auto;
    height: 14px;
    display: inline-block;
    transition: all 0.3s ease;
    img {
      width: auto;
      height: 100%;
    }
    @media screen and (max-width: $bp-phone) {
      display: flex;
      height: 12px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 0;
    border-bottom: 2px solid $is-blau;
    padding-bottom: 16px;
    @media screen and (max-width: $bp-phone) {
      padding: 16px;
    }
    @media screen and (min-width: $bp-phone) {
      padding-top: 32px;
      max-width: calc(100vw - 64px);
    }
    @media screen and (min-width: 1440px) {
      padding-top: 32px;
      max-width: calc(100vw - 200px);
    }
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 8px;
    @media screen and (max-width: $bp-phone) {
      display: none;
    }
    li {
      list-style: none;
    }
  }
  nav {
    .MenuHover {
      position: relative;
      ul {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        flex-direction: column;
        gap: 8px;
        //width: 224px;
        align-items: flex-start;
        background: #fff;
        padding: 16px 8px 8px;

        @media screen and (max-width: $bp-phone) {
          opacity: 1;
          background: transparent;
          position: relative;
          width: 100%;
        }

        li {
          background: #fff;
          display: flex;
          @media screen and (max-width: $bp-phone) {
            background: transparent;
          }
          a {
            //padding: 8px 8px;
            flex-grow: 1;
            white-space: nowrap;
            @media screen and (max-width: $bp-phone) {
              font-size: 16px;
            }
            &:hover {
              background: rgba($is-blau, 0.1);
            }
            &.active {
              background: $is-rot;
            }
          }
        }
      }
      &:hover {
        ul {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
    a {
      text-decoration: none;
      color: $is-blau;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;
      display: flex;
      gap: 8px;
      padding: 6px;
      align-items: center;
      @media screen and (min-width: $bp-phone) and (max-width: 1280px) {
        font-size: 14px;
      }
      @media screen and (max-width: $bp-phone) {
        svg {
          display: none;
        }
      }
      &.active {
        background: $is-rot;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      &.c2a {
        padding: 8px 24px;
        border-radius: 18px;
        background: $is-blau;
        color: #fff;
        margin-left: 40px;
      }
    }
    &.desktop {
      @media screen and (max-width: $bp-phone) {
        display: none !important;
      }
    }
    &.mobile {
      @media screen and (min-width: $bp-phone) {
        display: none !important;
      }
      display: flex;
      width: 32px;
      height: 32px;
      text-align: center;
      a {
        margin-bottom: 16px;
      }
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
      position: absolute;
      right: 16px;
      top: 22px;
      width: 24px;
      height: 20px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background-color: $is-blau;
      transition: all 0.3s ease;
      .haendler & {
        //background: #000;
      }
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      //background: $lime;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 48px;
      width: 48px;
      //transform: scale(2);
      top: 32px !important;
      right: 32px !important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #fff;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
      background: $is-blau;
      padding: 32px;
      font-size: 32px;
      width: 100%;
      //margin-left: 10%;
      position: relative;
      a {
        font-size: 20px;
        color: #fff;
        text-align: left;
      }
      ul {
        margin: 8px 0 32px;
        padding: 0;
        li {
          width: 100%;
          text-align: left;
        }
        a {
          font-size: 16px;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 48px 0;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(4px);
    }
  }
}
