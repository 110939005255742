@import "../vars";

.WorkIS-Subpages {
  display: grid;
  width: 100%;

  max-width: $max-width;
  grid-gap: 64px;
  grid-template-columns: 4fr 4fr;
  align-items: flex-start;

  @include content-margin;
  @include content-padding;

  @media screen and (max-width: $bp-phone) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .WorkIS-Subpage {
    display: flex;
    flex-direction: column;
    background: $is-grey;
    text-decoration: none;
    color: $is-blau;
    height: 100%;

    picture {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .WorkIS-Subpage-Text {
      display: flex;
      flex-direction: column;
      padding: 16px;
      align-items: flex-start;
      flex-grow: 1;
      gap: 24px;
      .texts {
        flex-grow: 1;
      }
      .headline {
        font-size: 28px;
      }
    }
  }
}
