@import "../vars";

.Teams-Hero {
  width: 100%;
  background: $is-blau;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding: 10vh 40px;
  text-align: center;
  flex-direction: column;

  height: 40vh;
  min-height: 300px;
  max-height: 600px;

  input {
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    width: 30vw;
    @media screen and (max-width: $bp-phone) {
      width: 80vw;
    }
    &:focus {
      outline: none;
      border: none;
    }
  }
}

.Teams {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include content-padding;

  .Teams-Content {
    width: 100%;
    max-width: $max-width;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
