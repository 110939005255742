@import "../vars";

.MemberSlider {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin-top: 64px;
  margin-bottom: 64px;
  .MemberSlider-text {
    width: 100%;
    max-width: $max-width;
    padding: 0 40px;
    margin: 0 auto;
    .row {
      align-items: flex-end;
      @media screen and (max-width: $bp-phone) {
        align-items: flex-start;
      }
    }
    .col {
      justify-content: flex-end;
    }
    h2 {
      margin-bottom: 0;
      font-size: 36px;
    }
    a {
      text-transform: uppercase;
      font-weight: 500;
      color: $is-blau;
    }
    span {
      text-align: center;
    }
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
      .row {
        flex-direction: column;
      }
    }
  }

  .MemberSlider-Slider {
    width: 100%;
    max-width: $max-width;
    padding: 40px;
    margin-bottom: 32px;
    &.swiper,
    .swiper-wrapper {
      overflow: visible;
    }
    .swiper-button-prev {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_prev.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-next {
      width: 48px;
      height: 48px;
      background: url("../assets/ic_slider_next.svg");
      background-size: 100% 100%;
      &:after {
        content: none;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .swiper-button-disabled {
      opacity: 0;
      &:hover {
        opacity: 0;
      }
    }
    .swiper-scrollbar {
      background: $is-grey;
      width: auto;
      left: $padding-desktop;
      right: $padding-desktop;
      @media screen and (max-width: $bp-phone) {
        left: $padding-mobile;
        right: $padding-mobile;
      }
      .swiper-scrollbar-drag {
        background: $is-rot;
      }
    }
  }

  .row {
    align-items: center;
    .col {
      &.right {
        align-items: flex-end;
      }
    }
  }

  .MemberSlider-Filter {
    display: inline-flex;
    flex-direction: row;
    background: $is-grey;
    color: $is-blau;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 16px;
    border-radius: 18px;
    li {
      display: flex;
      padding: 8px 32px;
      border-radius: 18px;
      cursor: pointer;
      user-select: none;
    }
    .active {
      background: $is-blau;
      color: #fff;
    }
  }

  .MemberSlider-Content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
