@import "../vars";

.PhotoContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  padding: 40px;

  @include content-margin;
  @include content-padding;

  .PhotoContent-image {
    width: 100%;
    max-width: 100%;
  }
  picture {
    width: 100%;
    max-width: 100%;
    img {
      width: 100%;
    }
  }
}
