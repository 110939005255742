@import "../vars";

.Job {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background: $is-grey;
  padding: 32px;
  gap: 32px;

  @media screen and (max-width: $bp-phone) {
    gap: 16px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;

    white-space: break-spaces;
    word-break: break-word;

    -webkit-hyphenate-limit-before: 7; /* For Safari */
    -webkit-hyphenate-limit-after: 7; /* For Safari */
    -ms-hyphenate-limit-chars: 10 3 4;
    hyphenate-limit-chars: 10 7 4;
  }

  h2 {
    font-size: 28px;
    margin-top: 8px;
    margin-bottom: 0;
    @media screen and (max-width: $bp-phone) {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: $bp-phone) {
      font-size: 14px;
    }
  }

  .Job-Text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (max-width: $bp-phone) {
      .row {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .subtitle,
  .date {
    font-size: 16px;
    @media screen and (max-width: $bp-phone) {
      font-size: 12px;
    }
  }

  .date {
    //min-width: 150px;
    white-space: nowrap;
  }
}
