@import "../vars";

.VideoGallery {
  width: 100%;
  max-width: $max-width;

  @include content-padding;
  @include content-margin;

  .VideoGallery-SplitView {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: 6fr 4fr;
    align-items: flex-start;
    min-height: 80vh;

    @media screen and (max-width: $bp-phone) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }

    h2 {
      margin-bottom: 32px;
    }

    .Video {
      width: 100%;
      height: 100%;

      .Video-Embed {
        width: 100%;
        padding-top: 16px;
        iframe {
          width: 100%;
          height: auto;
          aspect-ratio: calc(16 / 9);
        }
      }
    }
    .List {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .active {
        font-weight: 500;
      }
      .List-Category {
        background: $is-grey;
        .List-Category-Title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 32px;
          cursor: pointer;
          &:hover {
            background: rgba(#e1e2e5, 0.2);
          }
          .title {
            flex-grow: 1;
            margin: 0;
            font-size: 22px;
          }
          img {
            transition: all 0.2s ease;
            width: 32px;
            height: 32px;
            margin-left: 16px;
          }
        }
        ul {
          display: none;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding: 16px 32px;
            border-top: 1px solid #e1e2e5;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            &:hover {
              background: rgba(#e1e2e5, 0.2);
            }
            &.active {
              background: #e1e2e5;
            }
          }
        }
        &.active {
          .List-Category-Title {
            img {
              transform: rotate(180deg);
            }
          }
          ul {
            display: flex;
          }
        }
      }
    }
  }
}
