@import "fonts/DINPro.css";
@import "fonts/Radial.css";
@import "_vars.scss";

@import "slick-carousel/slick/slick.css";
@import "./helpers/slick-theme";

html,
body {
  margin: 0;
  padding: 0;
  background: #fff;
  @media screen and (max-width: $bp-phone) {
    width: 100vw;
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  font-family: $font-din;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  box-sizing: border-box;
  color: $is-blau;
  * {
    box-sizing: inherit;
  }
  @media screen and (max-width: $bp-phone) {
    margin-top: 60px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

em {
  font-style: normal;
}

/**************************/
/* CSS Animations Classes */
/**************************/

/*
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  //transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  //transform: translate(0, 0);

  transition: opacity 750ms ease-out, transform 1000ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  //transform: translate(0, 30px);

  transition: opacity 50ms ease-out, transform 1000ms ease;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

//h1,
//h2 {
//  @media screen and (max-width: $bp-phone) {
//    word-break: break-all;
//    // allow words to break with hyphens on phones
//    -webkit-hyphens: auto;
//    -ms-hyphens: auto;
//    hyphens: auto;
//
//    -webkit-hyphenate-limit-before: 3; /* For Safari */
//    -webkit-hyphenate-limit-after: 4; /* For Safari */
//    -ms-hyphenate-limit-chars: 10 3 4;
//    hyphenate-limit-chars: 10 3 4;
//  }
//}

h1 {
  text-transform: uppercase;
  font-family: $font-din;
  font-weight: 500;

  @include fp(font-size, 32, 64, 320, $max-width-pure);
  @media screen and (max-width: $bp-phone) {
    //font-size: 48px;
  }
}

h2 {
  font-family: $font-din;
  font-weight: 500;
  text-transform: uppercase;
  @include fp(font-size, 32, 48, 320, $max-width-pure);
}

h3 {
  font-size: 24px;
  font-family: $font-din;
  font-weight: 500;
  text-transform: uppercase;
  @media screen and (max-width: $bp-phone) {
    font-size: 18px;
  }
}

h4 {
  font-size: 24px;
  font-family: $font-din;
  font-weight: 500;
  text-transform: uppercase;
  @media screen and (max-width: $bp-phone) {
    font-size: 18px;
  }
}

a {
  color: $is-blau;
  &:visited {
    color: $is-blau;
  }
}

hr {
  display: block;
  appearance: none;
  width: 100%;
  height: 1px;
  background: rgba(22, 65, 150, 0.2);
}

.noBreak {
  word-break: normal;
}

.text-center {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.Rechtliches {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding: 16px;
  font-size: 20px;
  p {
    word-wrap: break-word;
  }
}

.mobile-only {
  @media screen and (min-width: $bp-phone) {
    display: none !important;
  }
}

.desktop-only {
  @media screen and (max-width: $bp-phone) {
    display: none !important;
  }
}
